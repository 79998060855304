<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <AdminStatMenu></AdminStatMenu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>
                                <v-col>
                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:15%"/>
                                            <col style="width:85%"/>
                                        </colgroup>

                                        <tr>
                                            <th class="th">객실구분</th>
                                            <td class="td">
                                                <input type="radio" id="" value="" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="">전체</label>
                                                <input type="radio" id="R" value="R" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="R">객실</label>
                                                <input type="radio" id="A" value="A" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="A">캠핑장A</label>
                                                <input type="radio" id="B" value="B" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="B">캠핑장B</label>
                                                <input type="radio" id="C" value="C" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="C">캠핑장C</label>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-3 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="searchClick"
                                                >
                                                    검색
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    월별 매출통계
                                </v-col>
                                <!--<v-col class="hidden-md-and-down">-->
                                <div class="overflow-x-auto">
                                    <table class="adminListTable">
                                        <colgroup>
                                            <col style="width:10%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                            <col style="width:18%"/>
                                        </colgroup>
                                        <tr>
                                            <th>
                                                <select v-model="resvYear" @change="changeYear($event)"
                                                        style="background-color: #fff;">
                                                    <option v-for="(n, i) in yearList" :value="n">{{n}}년</option>
                                                </select>
                                            </th>
                                            <th>신용카드</th>
                                            <th>계좌이체</th>
                                            <th>가상계좌</th>
                                            <th>무통장입금</th>
                                            <th>합계</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in statList"
                                                :key="i">
                                            <td>{{item.month}}월</td>
                                            <td>{{item.resvPrice_3 | comma}}원</td>
                                            <td>{{item.resvPrice_2 | comma}}원</td>
                                            <td>{{item.resvPrice_1 | comma}}원</td>
                                            <td>{{item.resvPrice_0 | comma}}원</td>
                                            <td>{{item.resvPrice_tot | comma}}원</td>
                                        </tr>
                                        <tr>
                                            <td>합계</td>
                                            <td>{{statInfo.resvPrice_3 | comma}}원</td>
                                            <td>{{statInfo.resvPrice_2 | comma}}원</td>
                                            <td>{{statInfo.resvPrice_1 | comma}}원</td>
                                            <td>{{statInfo.resvPrice_0 | comma}}원</td>
                                            <td>{{statInfo.resvPrice_tot | comma}}원</td>
                                        </tr>
                                    </table>
                                </div>
                            </v-row>
                            <v-row>
                                <v-col>
                                    ※입금완료된 내역만 표시됩니다.
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import AdminStatMenu from '../../components/AdminStatMenu'
    export default {
        name: 'AdminStatMonth',
        components: {AdminStatMenu},
        data: () => ({
            resvYear: "",
            yearList: [],
            statList: [],
            roomGubun: "",
            statInfo: {
                resvPrice_0: 0,
                resvPrice_1: 0,
                resvPrice_2: 0,
                resvPrice_3: 0,
                resvPrice_tot: 0,
            },

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            let now = new Date();	// 현재 날짜 및 시간
            let year = now.getFullYear();	// 연도
            this.resvYear = String(year);

            for (year; year > 2020; year--) {

                this.yearList.push(year);
            }

            this.getList({});

        },
        methods: {
            getList() {

                let formdata = {};

                if (this.resvYear.length !== 4) {
                    alert("잘못된 요청입니다.");
                    return;
                }

                let _this = this;

                formdata['resvYear'] = this.resvYear;
                formdata['roomGubun'] = this.roomGubun;

                return this.$store.dispatch("admin/getStatMonth",formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.statList = resp.message;

                            this.statInfo = {
                                resvPrice_0: 0,
                                resvPrice_1: 0,
                                resvPrice_2: 0,
                                resvPrice_3: 0,
                                resvPrice_tot: 0,
                            };

                            resp.sumInfo.forEach(function (e) {

                                _this.statInfo.resvPrice_tot += e.totResvPrice;

                                switch (e.payType) {
                                    case "0" : {

                                        _this.statInfo.resvPrice_0 = e.totResvPrice;
                                        break;
                                    }
                                    case "1" : {
                                        _this.statInfo.resvPrice_1 = e.totResvPrice;
                                        break;
                                    }
                                    case "2" : {
                                        _this.statInfo.resvPrice_2 = e.totResvPrice;
                                        break;
                                    }
                                    case "3" : {
                                        _this.statInfo.resvPrice_3 = e.totResvPrice;
                                        break;
                                    }

                                }

                            });


                        }, 300)
                    })
                    .catch((err) => {
                    })

            },

            searchClick() {
                let formdata = {
                    roomGubun: this.roomGubun
                };

                this.getList(formdata);

            },
            menuClick(route, roomGubun) {
                if (roomGubun != null) {
                    location.href = route + '?roomGubun=' + roomGubun;
                } else {
                    location.href = route;
                }

            },
            changeYear(event) {
                this.resvYear = event.target.value;
                this.getList();

            }

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
        },
    }
</script>

<style>

</style>

